import React, { Component, Fragment } from 'react';
import { Link, navigate } from 'gatsby';
// import { FirebaseContext } from './Firebase';
import logo from '../assets/images/luth-logo.png';

class NavThree extends Component {
  // static contextType = FirebaseContext;
  constructor() {
    super();
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // logout = () => {
  //   const { firebase } = this.context;
  //   firebase.logout().then(() => {
  //     window.location.reload();
  //     navigate('/schools');
  //   });
  // };

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false,
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector('.menu-toggler');
    let mainNav = document.querySelector('.main-navigation');

    mainNavToggler.addEventListener('click', function () {
      mainNav.style.display =
        mainNav.style.display !== 'block' ? 'block' : 'none';
    });
  };
  render() {
    // const { user } = this.context;
    return (
      <header className='site-header site-header__home-three '>
        <div className='topbar-one' style={{
                  backgroundColor : 'red',
                }}>
          <div className='container' >
            <div className='topbar-one__left'>              
              <Link className='blinking' to='/courses'>Applications for the LUTH Research Grants is currently Closed</Link>              
            </div>
           
          </div>
        </div>
        {/* <div className='topbar-one'>
          <div className='container'>
            <div className='topbar-one__left'>              
              <Link className='blinking' to='/courses'>Applications for the LUTH Research Grants is currently Open</Link>              
            </div>
            <div className='topbar-one__right'>
              {user === null ? (
                <Link to='/login'>Login</Link>
              ) : (
                <Fragment>
                  <Link to='/profile'>Profile</Link>
                  <a href='#' onClick={this.logout}>
                    Logout
                  </a>
                </Fragment>
              )}
            </div>
          </div>
        </div> */}
        <nav
          style={{ backgroundColor: 'white' }}
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? 'stricked-menu stricky-fixed' : ''
          }`}
        >
          <div className='container clearfix'>
            <div className='logo-box clearfix'>
              <Link to='/' className='navbar-brand'>
                <img
                  src={logo}
                  height={this.state.sticky && '75'}
                  className='main-logo'
                  alt='luth logo'
                />
              </Link>
              <button className='menu-toggler' data-target='.main-navigation'>
                <span className='kipso-icon-menu'></span>
              </button>
            </div>
            <div className='main-navigation'>
              <ul className=' navigation-box'>
                <li className='current'>
                  <Link to='/'>Home</Link>
                  {/* <ul className="sub-menu">
                    <li>
                      <Link to="/">Home 01</Link>
                    </li>
                    <li>
                      <Link to="/index-2">Home 02</Link>
                    </li>
                    <li>
                      <Link to="/index-3">Home 03</Link>
                    </li>
                    <li>
                      <a href="#none">Header Versions</a>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/">Header 01</Link>
                        </li>
                        <li>
                          <Link to="/index-2">Header 02</Link>
                        </li>
                        <li>
                          <Link to="/index-3">Header 03</Link>
                        </li>
                      </ul>
                    </li>
                  </ul> */}
                </li>
                {/* <li>
                  <a href="#none">Pages</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About Page</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing Plans</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ'S</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="courses.html">Courses</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/course-details">Courses Details</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="teachers.html">Teachers</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/teachers">Teachers</Link>
                    </li>
                    <li>
                      <Link to="/teacher-details">Teachers Details</Link>
                    </li>
                    <li>
                      <Link to="/become-teacher">Become Teacher</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="news.html">News</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/news">News Page</Link>
                    </li>
                    <li>
                      <Link to="/news-details">News Details</Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to='/manuscript/check-status'>Check Status </Link>
                </li>
                <li>
                  <Link to='/research/elegibilitycriteria'>Eligibility Criteria</Link>
                </li>
                <li>
                  <Link to='/grants'>Research Grant Home</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default NavThree;
