// src/components/UserPage.js
import React from 'react';
import { Link } from 'gatsby';
import '../assets/css/userpage.css'; // Ensure you create and style this CSS file accordingly
import logo from '../assets/images/luth-logo.png'; // Import the logo

const UserPage = () => {
//   const navigate = useNavigate();

  return (
    <div className='container-grants'>
    <div className="user-page-container">
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Lagos University Teaching Hospital Manuscript Submission for Grant</h1>
      </header>
      <main className="main-content">
        <div className="button-container">
          <a className='button'> <Link to='/research/elegibilitycriteria' style={{ color: 'white' }}>Eligibility Criteria</Link></a>
          <a className='button'><Link to='/manuscript/check-status' style={{ color: 'white' }}>Check Manuscript Status</Link></a>
          <a className='button'><Link to='/manuscript/manuscriptapply' style={{ color: 'white' }}>Submit Manuscript</Link></a>
          <a className='button'><Link to='/manuscript/evidence-upload' style={{ color: 'white' }}>Upload Evidence</Link></a>
          <a className='button'><Link to='/manuscript/replace-file-manu' style={{ color: 'white' }}>Replace Upload</Link></a>
        </div>
      </main>
    </div>
    </div>
  );
};

export default UserPage;
